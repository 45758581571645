import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
// import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';
import LinkPage from '@components/atoms/LinkPage';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#3CD5AF';
const footerRelatedLinks = [
	{
		title: 'Théâtre de Vidy-Lausanne',
		url: '/fr/projets/theatre-de-vidy-lausanne/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectAckTypoPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-cover.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-cover-mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-Medium.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-bold.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-caracteristiques-FR.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-affiche-01.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-affiche-02.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-application-01.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-application-02.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-application-03.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-application-04.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-typo-application-05.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_11: file(
					relativePath: {
						eq: "projects/ackermann/typography/img-link-brand.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/ackermann-typography/',
					lang: 'en',
				},
			]}
			title="Typographie Ackermann - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Typographie Ackermann"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Projet">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							La typographie dessinée pour Ackermann crée une
							force de distinction unique à la marque.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={['Typographie', 'Positionnement de marque']}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Le besoin de cohérence</h3>
						<p>
							Lors de la refonte de{' '}
							<LinkPage href="/fr/projets/ackermann-branding">
								l’identité visuelle d’Ackermann
							</LinkPage>
							, l’aspect de la cohérence était un élément central.
							Un bon pain nécessite des ingrédients locaux et de
							qualité, de même une identité visuelle forte
							nécessite un soin du détail et de la cohérence.
						</p>
						<p>
							Pour y répondre, nous avons dessiné une typographie
							sur mesure qui crée une relation forte avec la
							symbolique du logo et augmente la richesse visuelle.
						</p>

						<h3>Sujets associés</h3>
						<ButtonSecondary
							href="/fr/projets/ackermann-branding/"
							text="Refonte de la marque Ackermann"
							data-target
						/>
					</div>
				</div>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Entre tradition et progression</h3>
						<p>
							En mêlant la structure épurée des linéales
							contemporaines au contraste des capitales romaines,
							la typographie présente un parfait équilibre
							graphique entre style traditionnel et contemporain,
							en relation directe avec l’ADN d’Ackermann.
						</p>
						<p>
							Le rythme, très contrasté entre les verticales et
							les horizontales, donne un caractère fort et
							dynamique.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_1.childImageSharp}
							alt="Typographie medium"
						/>
						<Image
							{...data.image_2.childImageSharp}
							alt="Typographie bold"
						/>
					</div>
				</Column>

				<Column modifiers="left">
					<div className="column__media text-content">
						<Video src="5213937a7a2b9ddff0b1601bf489ec4c" alt="" />
						<Video src="e19f3a25549d8cf6c2009402a42b2267" alt="" />
						<Image
							{...data.image_3.childImageSharp}
							alt="Caractéristiques"
						/>
					</div>

					<div className="column__text column__sticky text-content">
						<h3>Un travail de lisibilité</h3>
						<p>
							Fondées sur le dessin de lettrage du logo Ackermann,
							les lettres ont été retravaillées. Chasse réduite,
							réduction du contraste et de la graisse… l’objectif
							est de proposer la meilleure lisibilité possible et
							de créer une légère distinction avec la typographie
							du logo.
						</p>
						<p>
							On retrouve sur les fûts la marque des formes
							concaves inspirées de la gravure sur pierre. Cette
							caractéristique augmente le sens humain et
							authentique de la marque.
						</p>
					</div>
				</Column>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_4.childImageSharp} alt="Affiche" />
					<Image {...data.image_5.childImageSharp} alt="Affiche" />
				</Gallery>

				<Image
					className="col-full"
					{...data.image_6.childImageSharp}
					alt="Application"
				/>

				<Image
					className="col-full grid-spacing"
					{...data.image_7.childImageSharp}
					alt="Application"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image
						{...data.image_8.childImageSharp}
						alt="Application"
					/>
					<Image
						{...data.image_9.childImageSharp}
						alt="Application"
					/>
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_10.childImageSharp}
					alt="Application"
				/>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>
							Découvrez d’autres réalisations du projet Ackermann
						</h3>
						<p>
							Découvrez l’identité visuelle et le site
							d’interaction qui font vivre la typographie
							Ackermann.
						</p>
						<ButtonSecondary
							href="/fr/projets/ackermann-branding/"
							text="Voir l’identité visuelle"
							data-target
						/>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_11.childImageSharp}
							alt="Branding"
						/>
					</div>
				</Column>
			</section>
		</Layout>
	);
};

export default ProjectAckTypoPage;
